<script setup lang="ts">
import type { PropType } from 'vue';
import { useInfiniteScroll } from '~/composables/infinite-scroll';
import { getApiRoot } from '~/utilities/api';

const props = defineProps({
  profile: { type: Object as PropType<any>, required: true },
  type: { type: String as PropType<'owned' | 'created'>, required: true },
  gridStyle: { type: String as PropType<'grid-compact' | 'grid' | 'table'>, required: true },
});

const bottom = ref();
const { items, pending } = useInfiniteScroll(
  bottom,
  `${getApiRoot()}/profile/${props.profile.id}/nfts?type=${props.type}`
);
</script>

<template>
  <div>
    <ExploreGrid :nfts="items" :pending="pending" :grid-style="props.gridStyle" />
    <div ref="bottom"></div>
  </div>
</template>
