<script setup lang="ts">
import VueHcaptcha from '@hcaptcha/vue3-hcaptcha';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { CheckIcon, StarIcon } from '@heroicons/vue/solid';
import { watchDebounced } from '@vueuse/core';
import type { PropType } from 'vue';
import { getApiRoot } from '~/utilities/api';

const { $axios } = useNuxtApp();

const props = defineProps({
  modelValue: Boolean as PropType<boolean>,
  profile: { type: Object as PropType<any>, required: true },
});
const emit = defineEmits(['update:modelValue', 'addedNft']);

const nftId = ref(undefined);

const submitInProgress = ref(false);
const submissionError = ref(undefined);

watch(
  () => props.modelValue,
  (newShow, oldShow) => {
    if (newShow && !oldShow) {
      nftId.value = undefined;
      nft.value = undefined;
    }
  }
);

const nft = ref();
watchDebounced(
  nftId,
  async () => {
    if (nftId.value) {
      try {
        nft.value = await $fetch(`${getApiRoot()}/nfts/${nftId.value}`);
      } catch (e) {
        nft.value = undefined;
      }
    } else {
      nft.value = undefined;
    }
  },
  { debounce: 300 }
);

const submit = async () => {
  if (!nft.value) {
    submissionError.value = 'NFT is required.';
    return;
  }

  submitInProgress.value = true;
  submissionError.value = undefined;

  const data = {
    nft_id: nftId.value,
  };
  try {
    const { data: addedNft } = await $axios({
      method: 'put',
      url: `${getApiRoot()}/user/profiles/${props.profile.id}/featured`,
      data,
    });
    emit('addedNft', addedNft);
    emit('update:modelValue', false);
  } catch (e) {
    submissionError.value = e.response?.data?.detail || e.message;
  } finally {
    submitInProgress.value = false;
  }
};
</script>
<template>
  <TransitionRoot as="template" :show="props.modelValue">
    <Dialog as="div" class="relative z-50" @close="emit('update:modelValue', false)">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="bg-opacity-75 fixed inset-0 bg-neutral-500 transition-opacity dark:bg-neutral-400/70" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all dark:bg-black sm:my-8 sm:w-full sm:max-w-xl sm:p-6"
            >
              <div>
                <div class="sm:flex sm:items-start">
                  <div
                    class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-amber-100 sm:mx-0 sm:h-10 sm:w-10"
                  >
                    <StarIcon class="h-6 w-6 text-amber-600" aria-hidden="true" />
                  </div>
                  <div class="mt-3 flex flex-col gap-2 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <DialogTitle as="h3" class="text-lg font-medium leading-6 text-neutral-900 dark:text-neutral-50">
                      Add featured NFT
                    </DialogTitle>
                    <div>
                      <p class="text-sm text-neutral-500 dark:text-neutral-400">
                        Add one of your created or collected NFTs to your "Featured" tab.
                      </p>
                    </div>
                    <div class="mt-2">
                      <label for="nftid" class="block text-sm font-medium text-neutral-700 dark:text-neutral-200"
                        >NFT ID</label
                      >
                      <input
                        id="nftid"
                        v-model="nftId"
                        name="nftid"
                        type="text"
                        placeholder="nft1apxrzufgt8sntpfl25rzkfxxz2ypkzke3nm83u9qznjydntuxvzqtr33gr"
                        class="mt-1 block w-full rounded-md border-neutral-300 py-2 pl-3 pr-10 text-base focus:border-black focus:ring-black sm:text-sm"
                      />
                    </div>
                    <div class="mt-2 flex justify-start">
                      <NftCard :nft="nft" :compact="true" class="aspect-1 h-48" />
                    </div>
                    <div v-if="submissionError">
                      <div class="text-sm font-medium text-red-600">Error: {{ submissionError }}</div>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    :disabled="submitInProgress"
                    class="inline-flex w-full justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-emerald-700 dark:text-black sm:ml-3 sm:w-auto sm:text-sm"
                    @click="submit"
                  >
                    <svg
                      v-if="submitInProgress"
                      class="-ml-1 mr-3 h-5 w-5 animate-spin text-white dark:text-black"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                      <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Add
                  </button>
                  <button
                    ref="cancelButtonRef"
                    type="button"
                    class="mt-3 inline-flex w-full justify-center rounded-md border border-neutral-300 bg-white px-4 py-2 text-base font-medium text-neutral-700 shadow-sm hover:bg-neutral-50 dark:bg-black dark:text-neutral-200 dark:hover:bg-neutral-900 sm:mt-0 sm:w-auto sm:text-sm"
                    @click="emit('update:modelValue', false)"
                  >
                    Close
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
