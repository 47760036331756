<script setup lang="ts">
import { PlusIcon, XIcon } from '@heroicons/vue/outline';
import type { PropType } from 'vue';
import type { Singleton } from '~/types/singleton';
import { getApiRoot } from '~/utilities/api';

const props = defineProps({
  nfts: Object as PropType<Array<Singleton>>,
  profile: { type: Object as PropType<any>, required: true },
  pending: Boolean as PropType<boolean>,
  gridStyle: String as PropType<'grid-compact' | 'grid' | 'table'>,
});
const { nfts, pending, gridStyle, profile } = toRefs(props);

const showAddNftModal = ref(false);

const { $axios } = useNuxtApp();
const removeFeatured = async (nftId) => {
  await $axios({
    method: 'delete',
    url: `${getApiRoot()}/user/profiles/${props.profile.id}/featured`,
    data: {
      nft_id: nftId,
    },
  });
  const index = nfts.value.findIndex((nft) => nft.id === nftId);
  if (index > -1) {
    nfts.value.splice(index, 1);
  }
};
const addedNft = (nft) => {
  nfts.value.unshift(nft);
};
</script>

<template>
  <div
    v-if="gridStyle !== 'table'"
    :class="[
      gridStyle === 'grid-compact'
        ? 'grid-cols-2 gap-x-6 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6'
        : 'grid-cols-1 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-3 2xl:grid-cols-4 ',
    ]"
    class="grid w-full gap-y-4 sm:gap-y-6 lg:gap-6 2xl:gap-8"
  >
    <div v-for="nft in nfts" :key="`${nft.id}${nft.auction?.id}`" class="flex flex-col">
      <NftCard :nft="nft" :compact="gridStyle === 'grid-compact'" class="flex-1" />
      <button
        @click="removeFeatured(nft.id)"
        class="mt-2 flex items-center justify-center gap-0.5 text-xs text-neutral-400 dark:text-neutral-500"
      >
        <XIcon class="h-5 w-5" />Remove
      </button>
    </div>
    <div class="flex flex-col">
      <div
        class="shadow-card hover:shadow-card-hover group relative flex flex-1 cursor-pointer flex-col overflow-hidden rounded-lg bg-white transition duration-300 ease-in-out dark:bg-black betterhover:hover:-translate-y-0.5"
        @click="showAddNftModal = true"
      >
        <div class="aspect-h-1 aspect-w-1 relative border-b">
          <div class="flex items-center justify-center">
            <PlusIcon class="h-1/2 w-1/2 text-neutral-300 dark:text-neutral-600" />
          </div>
        </div>
      </div>
      <div class="h-7"></div>
    </div>
    <template v-if="pending">
      <NftCard v-for="index in 12" :key="index" :nft="undefined" :compact="gridStyle === 'grid-compact'" />
    </template>
    <ProfileFeaturedAddNftModal v-model="showAddNftModal" :profile="profile" @addedNft="addedNft($event)" />
  </div>
</template>
