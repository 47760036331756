<script setup lang="ts">
import type { PropType } from 'vue';
import { getApiRoot } from '~/utilities/api';

const props = defineProps({
  profile: { type: Object as PropType<any>, required: true },
});
const { profile } = toRefs(props);

const { data: mints, pending } = await useFetch(`${getApiRoot()}/profile/${profile.value.id}/mints`);
</script>
<template>
  <div class="mx-auto flex w-full flex-col px-0 dark:text-white">
    <div class="mt-4 flex flex-col gap-4 lg:mt-4 lg:gap-4">
      <div
        class="grid w-full grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-6 lg:grid-cols-3 lg:gap-6 2xl:grid-cols-4 2xl:gap-8"
      >
        <MintCard v-for="mint in mints" :key="mint.id" :mint="mint" />
        <template v-if="pending">
          <MintCard v-for="index in 12" :key="index" :mint="undefined" />
        </template>
      </div>
    </div>
  </div>
</template>
