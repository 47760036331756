<script setup lang="ts">
import type { PropType } from 'vue';
import { useInfiniteScroll } from '~/composables/infinite-scroll';
import { getApiRoot } from '~/utilities/api';

const props = defineProps({
  profile: { type: Object as PropType<any>, required: true },
});

const bottom = ref();
const { items, pending } = useInfiniteScroll(bottom, `${getApiRoot()}/profile/${props.profile.id}/collections`);
</script>

<template>
  <div>
    <ExploreCollectionGrid :collections="items" :pending="pending" />
    <div ref="bottom"></div>
  </div>
</template>
