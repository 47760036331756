<script setup lang="ts">
import VueHcaptcha from '@hcaptcha/vue3-hcaptcha';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { CheckIcon, ExclamationIcon } from '@heroicons/vue/solid';
import type { PropType } from 'vue';
import { getApiRoot } from '~/utilities/api';

const { $axios } = useNuxtApp();

const props = defineProps({
  modelValue: Boolean as PropType<boolean>,
  profileId: String as PropType<string>,
  collectionId: String as PropType<string>,
  nftId: String as PropType<string>,
});
const emit = defineEmits(['update:modelValue']);

const reason = ref(undefined);
const comment = ref(undefined);
const captchaToken = ref(undefined);
const reasons = ['Impersonation', 'Spam', 'Illegal Content', 'Other'];

const submitInProgress = ref(false);
const isSubmitted = ref(false);
const submissionError = ref(undefined);

watch(
  () => props.modelValue,
  (newShow, oldShow) => {
    if (newShow && !oldShow) {
      reason.value = undefined;
      comment.value = undefined;
      isSubmitted.value = false;
    }
  }
);

const setCaptcha = ($event) => {
  captchaToken.value = $event;
};

const submit = async () => {
  if (!reason.value) {
    submissionError.value = 'Reason is required.';
    return;
  }

  if (!captchaToken.value) {
    submissionError.value = 'Captcha is required.';
    return;
  }

  submitInProgress.value = true;
  submissionError.value = undefined;

  const data = {
    profile_id: props.profileId,
    reason: reason.value,
    comment: comment.value,
    captcha_token: captchaToken.value,
  };
  try {
    await $axios({
      method: 'post',
      url: `${getApiRoot()}/blocklist/report`,
      data,
    });
    isSubmitted.value = true;
  } catch (e) {
    submissionError.value = e.response?.data?.detail || e.message;
  } finally {
    submitInProgress.value = false;
  }
};
</script>
<template>
  <TransitionRoot as="template" :show="props.modelValue">
    <Dialog as="div" class="relative z-50" @close="emit('update:modelValue', false)">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="bg-opacity-75 fixed inset-0 bg-neutral-500 transition-opacity dark:bg-neutral-400/70" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all dark:bg-black sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
            >
              <div v-if="isSubmitted">
                <div>
                  <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckIcon class="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div class="mt-3 text-center sm:mt-5">
                    <DialogTitle as="h3" class="text-lg font-medium leading-6 text-neutral-900 dark:text-neutral-50">
                      Report submitted
                    </DialogTitle>
                    <div class="mt-2">
                      <p class="text-sm text-neutral-500 dark:text-neutral-400">
                        You can find it in the
                        <NuxtLink
                          class="font-medium text-black dark:text-white"
                          target="_blank"
                          to="https://github.com/dexie-space/blocklist"
                          >Community Blocklist
                        </NuxtLink>
                        .
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6">
                  <button
                    type="button"
                    class="inline-flex w-full justify-center rounded-md border border-neutral-300 bg-white px-4 py-2 text-base font-medium text-neutral-700 shadow-sm hover:bg-neutral-50 dark:border-neutral-600 dark:bg-black dark:text-neutral-200 dark:hover:bg-neutral-900 sm:text-sm"
                    @click="emit('update:modelValue', false)"
                  >
                    Close
                  </button>
                </div>
              </div>

              <div v-else>
                <div class="sm:flex sm:items-start">
                  <div
                    class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
                  >
                    <ExclamationIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div class="mt-3 flex flex-col gap-2 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <DialogTitle as="h3" class="text-lg font-medium leading-6 text-neutral-900 dark:text-neutral-50">
                      Report profile
                    </DialogTitle>
                    <div>
                      <p class="text-sm text-neutral-500 dark:text-neutral-400">
                        Use this form to report profiles for rule violations. <br />Every report becomes a public pull
                        request in the
                        <NuxtLink
                          class="font-medium text-black dark:text-white"
                          target="_blank"
                          to="https://github.com/dexie-space/blocklist"
                          >Community Blocklist
                        </NuxtLink>
                        repository, where it will be reviewed by a maintainer.
                      </p>
                    </div>
                    <div class="mt-2">
                      <label for="reason" class="block text-sm font-medium text-neutral-700 dark:text-neutral-200"
                        >Reason</label
                      >
                      <select
                        id="reason"
                        v-model="reason"
                        name="reason"
                        class="mt-1 block w-full rounded-md border-neutral-300 py-2 pl-3 pr-10 text-base focus:border-black focus:ring-black dark:border-neutral-600 dark:bg-neutral-800 dark:text-white sm:text-sm"
                      >
                        <option v-for="option in reasons" :key="option" :value="option">{{ option }}</option>
                      </select>
                    </div>
                    <div>
                      <label for="comment" class="block text-sm font-medium text-neutral-700 dark:text-neutral-200"
                        >Additional notes</label
                      >
                      <div class="mt-1">
                        <textarea
                          id="comment"
                          v-model="comment"
                          rows="4"
                          name="comment"
                          class="block w-full rounded-md border-neutral-300 shadow-sm focus:border-black focus:ring-black dark:border-neutral-600 dark:bg-neutral-800 dark:text-white sm:text-sm"
                        />
                      </div>
                    </div>
                    <div class="py-2">
                      <vue-hcaptcha
                        sitekey="13889f75-e06e-4bf3-96e4-d0024b90cbe2"
                        @verify="setCaptcha($event)"
                      ></vue-hcaptcha>
                    </div>
                    <div v-if="submissionError">
                      <div class="text-sm font-medium text-red-600">{{ submissionError }}</div>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    :disabled="submitInProgress"
                    class="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 dark:text-black sm:ml-3 sm:w-auto sm:text-sm"
                    @click="submit"
                  >
                    <svg
                      v-if="submitInProgress"
                      class="-ml-1 mr-3 h-5 w-5 animate-spin text-white dark:text-black"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                      <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Report
                  </button>
                  <button
                    ref="cancelButtonRef"
                    type="button"
                    class="mt-3 inline-flex w-full justify-center rounded-md border border-neutral-300 bg-white px-4 py-2 text-base font-medium text-neutral-700 shadow-sm hover:bg-neutral-50 dark:border-neutral-600 dark:bg-black dark:text-neutral-200 dark:hover:bg-neutral-900 sm:mt-0 sm:w-auto sm:text-sm"
                    @click="emit('update:modelValue', false)"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
