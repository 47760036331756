<script setup lang="ts">
import type { PropType } from 'vue';
import { useInfiniteScroll } from '~/composables/infinite-scroll';
import { getApiRoot } from '~/utilities/api';

const props = defineProps({
  profile: { type: Object as PropType<any>, required: true },
});

const bottom = ref();
const { items: events } = useInfiniteScroll(bottom, () => `${getApiRoot()}/events?owner_id=${props.profile.id}`);
</script>

<template>
  <div>
    <EventsTable :events="events" :show-sender="true" />
    <div ref="bottom"></div>
  </div>
</template>
